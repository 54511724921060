<template>
  <a href="javascript:void(0);" id="toggle_btn">
    <i class="fas fa-align-left text-pink"></i>
  </a>
  <h3 class="page-title page-title-display" v-if="$route.params.title">
    {{ user }}
  </h3>
  <ul class="breadcrumb breadcrumb-display" v-else>
    <li class="breadcrumb-item d-inline-block">
      <router-link to="/app">
        <i class="fas fa-home text-pink"></i>
        Início
      </router-link>
    </li>
    <template
      v-for="(breadcrumb, index) in breadcrumbStore.breadcrumbs"
      :key="index"
    >
      <li
        class="breadcrumb-item d-inline-block"
        :class="{
          active: breadcrumb.active
        }"
      >
        <template v-if="!breadcrumb.active">
          <router-link
            v-if="breadcrumb.to"
            :to="breadcrumb.to"
            class="breadcrumb-item d-inline-block"
          >
            {{ breadcrumb.name }}
          </router-link>
        </template>
        <template v-else>
          {{ breadcrumb.name }}
        </template>
      </li>
    </template>
  </ul>
</template>

<script>
import User from "@/modules/auth/models/User";
import DefaultPageHeader from "@/components/shared/PageHeader/DefaultPageHeader";
import { useBreadcrumbStore } from "@/store/breadcrumb";

export default {
  name: "Toggle",
  components: {
    DefaultPageHeader
  },
  data() {
    return {
      USER: User.query().first(),
      user: "",
      breadcrumbStore: useBreadcrumbStore()
    };
  },
  mounted() {
    const firstName = this.USER.name.trim().split(" ");

    const name =
      firstName[0].charAt(0).toUpperCase() +
      firstName[0].slice(1).toLowerCase();

    this.$route.params.title ? (this.user = "Olá, " + name) : "";
  }
};
</script>
<style scoped>
.text-pink {
  color: #ff69b4 !important;
}
</style>
